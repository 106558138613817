:root {
  --ecru: #e4ae2b;
  --dark: #042487;
  --text: #055294;
  --head: 'Alice', serif;
  --sub: 'Crimson Pro', serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--head);
  color: var(--text);

}

.body {
  width: 62.5%;
  /* For use with REMS */
  margin: 0 auto;
}

.app {
  text-align: center;
  font-family: var(--sub);
  color: var(--text);
}

/* NAVIGATION CSS */
.nav-bar {
  background: var(--dark);
  height: 8rem;
  display: flex;
  justify-content: space-evenly;
}

.logo {
  height: 8rem;
  width: 8rem;
  margin-left: 2rem; 
}
#nav-toggle{
  margin: 2rem auto;
  justify-content: flex-end;
  margin-left: 50rem;
}
.nav-menu {
  background: var(--ecru);
}

.nav-item {
  display: flex;
  justify-content: space-around;
  font-size: 1.5rem;
  color: var(--dark)
}

.link {
  color: var(--text);
  text-decoration: underline overline;
}

.link:hover {
  color: var(--ecru);
  background: var(--dark);
  text-decoration: underline overline;
}

/* LANDING PAGE CSS */
@keyframes spin {
  from {
    transform: translate(0, 0) rotate(0);
  }

  to {
    transform: translate(0%, 0%) rotate(360deg);
  }
}

.coverPage {
  border-radius: 2rem;
  border: .5rem solid var(--ecru);
  animation: spin 10s linear infinite;
}

.portrait {
  border-radius: 2rem;
  border: .1rem solid white;
}

.landWrapper {
  margin-top: 10%;

}

.design {
  position: relative;
  width: 300px;
  height: 300px;
  margin: auto;
  margin-top: 100px;
}

.design>*,
.blend>* {
  position: absolute;
  height: 100%;
  width: 100%;
}

.rotatedBorder {
  border: 15px var(--dark) solid;
  margin: 0 auto;
  transform: rotate(45deg);
  box-shadow: 0 0 10px var(--dark), inset 0 0 20px var(--dark);
  border-radius: 2rem;
  animation: spin 5s linear infinite;
  animation-direction: reverse;
}


.grid {
  display: grid;
  grid: repeat(2, 1fr) / repeat(2, 1fr);
}

[data-white] {
  background-color: white;
}

.blend {
  mix-blend-mode: multiply;
}

.coverPage {
  object-fit: cover;
}

.design>.coverPage {
  box-shadow: 0 0 20px var(--ecru);
}


/*   ABOUT CSS */
.a-page section {
  display: flex;
  justify-content: space-between;
  margin: 1rem auto;
  font-size: 1.75rem;
}

.a-page section div {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25%;
  margin: 0 auto;
  font-size: 1.5rem;
}

.a-page section p {
  width: 75%;
  margin: 0 auto;
}

.career-story {
  font-size: 1.5rem;
}

.libutton {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 7px;
  text-align: center;
  outline: none;
  width: 90%;
  height: 42px;
  border-radius: 16px;
  background-color: var(--dark);
  color: var(--ecru);
  font-family: var(--sub);
  margin: 0 auto;
  margin-bottom: .5rem;

}

.libutton:hover {
  color: var(--dark);
  background-color: var(--ecru);
}

/*  FOOTER CSS */
.c-nav-footer {
  display: flex;
  flex-direction: column;
  margin: 0 auto;

}

.c-btn {
  background: var(--dark);
  color: var(--ecru);
  height: 5rem;
  margin: .5rem;
  vertical-align: middle;
}

.c-btn img {
  height: 3rem;
  display: block;
  margin: 0 auto;
  filter: drop-shadow(2px 6px 8px var(--ecru))
}

.c-btn:hover{
  background-color: var(--ecru);
  color: var(--dark)
}

.copyright {
  color: var(--ecru);
}

.s-btn {
  background: var(--ecru);
  color: var(--dark);
  margin-top: .5rem;
}



/* SHOWCASE CSS */

.showcase{
  font-size: 1rem;
  width: 100%;
}

.sc-img-container {
  width: 90%;
  margin: 0 auto;
}

.sc-image {
  width: 100%;
}


.sc-btn {
  display: flex;
  justify-content: center;
  padding: 7px;
  text-align: center;
  outline: none;
  width: 200px;
  height: 32px;
  border-radius: 16px;
  background-color: var(--dark);
  color: var(--ecru);
  margin: 0 auto;
}
.sc-btn:hover{
  color:var(--dark);
  background-color: var(--ecru);
}

.project-btns {
  display: flex;
  justify-content: center;
  padding: 7px;
  text-align: center;
  outline: none;
  margin: 0 auto;
}

.project-btn{
  width: 90%;
  height: 32px;
  border-radius: 16px;
  background-color: var(--dark);
  color: var(--ecru);
  margin: 0 1rem; 
}

.project-btn:hover{
  background-color: var(--ecru);
  color: var(--dark)
}
.sc-card-body {
  margin: 0 auto;
  font-family: var(--sub);
  color:var(--text);
  font-size: 1rem;
}


.sc-section-btns {
  margin: 1rem;
  display: flex;
  justify-content: center;
  width: 90%;
}

/* Media Queries */


@media only screen and (max-width: 900px) {
  .nav-bar {
    display: flex;
    padding-right: 2rem;
     
  }

.nav-item{
  flex-direction: column;
  justify-content: space-around;
  
}

#nav-toggle{
  margin-left: 0;
  margin-right: 0;
}
  .design {
    width: 10rem;
    height: 10rem;
    margin: 3rem auto;
  }

  .a-page section {
    display: block;
  }

  .a-page div:nth-child(2) img {
    display: none;
  }

}

@media only screen and (max-width: 350px) {
  .nav-item{
    margin: 0 auto;
    margin-right: 2.5rem;
  }
  .copyright{
    font-size: .5rem;
  }
}